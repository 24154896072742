/* eslint-disable no-undef */
import { api, kpi_api } from './common'
import { handleErrors } from '../middlewares/interceptor'
const noCache = require('superagent-no-cache')
const request = require('superagent')

import { DataTransformer } from '@quorumsco/quorum-helpers/lib/filter/DataTransformer'

/**
 * detect IE
 * returns version of IE or false, if browser is not Internet Explorer
 */
export function detectIE() {
	let ua = window.navigator.userAgent

	let msie = ua.indexOf('MSIE ')

	if (msie > 0) {
		// IE 10 or older => return version number
		return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10)
	}

	let trident = ua.indexOf('Trident/')

	if (trident > 0) {
		// IE 11 => return version number
		let rv = ua.indexOf('rv:')

		return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10)
	}

	let edge = ua.indexOf('Edge/')

	if (edge > 0) {
		// Edge (IE 12+) => return version number
		return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10)
	}

	// other browser
	return false
}

const finalNoCache = !detectIE() ? noCache : noCache.withQueryStrings

const middlewareSearchQueries = (req) => {
	if (req._data) DataTransformer(req._data)
}

const defaultOpts = {
	params: {
		Accept: 'application/json',
	},
	catchable: true,
}

if (XCUSTOM_USER_AGENT) {
	defaultOpts.params['X-Custom-User-Agent'] = XCUSTOM_USER_AGENT
}

const formatOpts = (opts = {}) => {
	opts.params = opts.params !== undefined ? opts.params : defaultOpts.params
	opts.catchable = opts.catchable !== undefined ? opts.catchable : defaultOpts.catchable

	return opts
}

const onError = (e, url) => {
	const urlNotCatchable = [
		'/session',
		'/me',
		'/search/nbstreets',
		'/kpi-presence-status',
		'/user/validation',
		'/auth/validation',
		'/access_token',
	]
	let code
	let message

	if (e.response && e.response.body && e.response.body.data) {
		code = e.response.body.data.code
		message = e.response.body.data.message
	}

	if (!urlNotCatchable.includes(url)) {
		handleErrors(code || e.status, e.response, message)
	}

	throw e // do so to send e in next catch()
}

function getCookie(cname) {
	let name = cname + '='
	let decodedCookie = decodeURIComponent(document.cookie)
	let ca = decodedCookie.split(';')
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i]
		while (c.charAt(0) == ' ') {
			c = c.substring(1)
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length)
		}
	}
	return ''
}

/**
 * @method $request
 * @param {String} method HTTP method (GET,POST,PATCH,DELETE...)
 * @param {String} url endpoint to get, without prefix
 * @param {Object} [data=null] data to send
 * @param {Object} [opts]
 * @param {Object} [opts.params={Accept: 'application/json'}] the http headers
 * @param {Boolean} [opts.catchable=true] do the default error handling... or not
 * @param {Boolean} files object with files
 * @example
 * await $request('GET', '/me')
 */
export const $request = (method, url, data = null, opts, files = {}, docType) => {
	opts = formatOpts(opts)

	switch (method) {
		case 'DELETE/JSON':
			return request('DELETE', `${api}${url}`)
				.use(finalNoCache)
				.withCredentials()
				.set(opts.params)
				.send({ data })
				.catch(
					opts.catchable
						? (e) => onError(e, url)
						: (e) => {
								throw e
							},
				)
		case 'POST':
		case 'PUT':
		case 'PATCH': {
			let apiUrl = api

			if (url.includes('http')) apiUrl = ''

			return request(method, `${apiUrl}${url}`)
				.use(finalNoCache)
				.withCredentials()
				.set(opts.params)
				.send({ data })
				.use(middlewareSearchQueries)
				.catch(
					opts.catchable
						? (e) => onError(e, url)
						: (e) => {
								throw e
							},
				)
		}

		case 'POST/BLOB': {
			const realMethod = method.split('/')[0]
			let apiUrl = api

			if (url.includes('http')) apiUrl = ''

			return request(realMethod, `${apiUrl}${url}`)
				.use(finalNoCache)
				.withCredentials()
				.set(opts.params)
				.responseType('blob')
				.send({ data })
				.catch(
					opts.catchable
						? (e) => onError(e, url)
						: (e) => {
								throw e
							},
				)
		}

		case 'POST/WITHOUT_DATA_KEY': {
			const realMethod = method.split('/')[0]
			let apiUrl = api

			if (url.includes('http')) apiUrl = ''

			return request(realMethod, `${apiUrl}${url}`)
				.use(finalNoCache)
				.withCredentials()
				.set(opts.params)
				.send({ ...data })
				.use(middlewareSearchQueries)
				.catch(
					opts.catchable
						? (e) => onError(e, url)
						: (e) => {
								throw e
							},
				)
		}
		case 'PATCH/WITHOUT_DATA_KEY': {
			const realMethod = method.split('/')[0]
			let apiUrl = api

			if (url.includes('http')) apiUrl = ''

			return request(realMethod, `${apiUrl}${url}`)
				.use(finalNoCache)
				.withCredentials()
				.set(opts.params)
				.type('application/x-www-form-urlencoded')
				.send({ ...data })
				.catch(
					opts.catchable
						? (e) => onError(e, url)
						: (e) => {
								throw e
							},
				)
		}
		case 'PATCH/WITHOUT_DATA_KEY_NOT_FORM': {
			const realMethod = method.split('/')[0]
			let apiUrl = api

			if (url.includes('http')) apiUrl = ''

			return request(realMethod, `${apiUrl}${url}`)
				.use(finalNoCache)
				.withCredentials()
				.set(opts.params)
				.send({ ...data })
				.catch(
					opts.catchable
						? (e) => onError(e, url)
						: (e) => {
								throw e
							},
				)
		}
		case 'POST/POST_JSON_TO_N8N': {
			const realMethod = method.split('/')[0]

			return request(realMethod, url)
				.use(finalNoCache)
				.set(opts.params)
				.send(data)
				.catch(
					opts.catchable
						? (e) => onError(e, url)
						: (e) => {
								throw e
							},
				)
		}
		case 'PUT/FORM':
		case 'PATCH/FORM': {
			const type = method.split('/')[1].toLowerCase()
			const realMethod = method.split('/')[0]
			let apiUrl = api

			if (url.includes('http')) apiUrl = ''

			return request(realMethod, `${apiUrl}${url}`)
				.use(finalNoCache)
				.withCredentials()
				.set(opts.params)
				.type(type)
				.send(data)
				.catch(
					opts.catchable
						? (e) => onError(e, url)
						: (e) => {
								throw e
							},
				)
		}
		case 'POST/TEXT':
		case 'POST/MULTIPART': {
			let r = request.post(`${api}${url}`)

			if (docType && docType !== 'link') {
				for (const [fileField, file] of Object.entries(files)) {
					r = r.attach(fileField, file)
				}
			}

			for (const [field, value] of Object.entries(data)) {
				r = r.field(field, value)
			}

			return r.then((res) => {
				return res
			})
		}

		case 'PATCH/MULTIPART': {
			let req = request.patch(`${api}${url}`)

			for (const [fileField, file] of Object.entries(files)) {
				req = req.attach(fileField, file)
			}

			for (const [field, value] of Object.entries(data)) {
				req = req.field(field, value)
			}

			return req.then((res) => {
				return res
			})
		}
		case 'POST/FORM': {
			const localType = method.split('/')[1].toLowerCase()
			const realMethod = method.split('/')[0]
			let apiUrl = api

			if (url.includes('http')) apiUrl = ''

			return request(realMethod, `${apiUrl}${url}`)
				.use(finalNoCache)
				.withCredentials()
				.set(opts.params)
				.type(localType)
				.send(data)
				.use(middlewareSearchQueries)
				.catch(
					opts.catchable
						? (e) => onError(e, url)
						: (e) => {
								throw e
							},
				)
		}
		case 'GET_EXT':
			return request('GET', url)
				.use(finalNoCache)
				.withCredentials()
				.catch(
					opts.catchable
						? (e) => onError(e, url)
						: (e) => {
								throw e
							},
				)
		case 'GET_KPI':
			// return request('GET', `${kpi_api}${url}`)
			return request('GET', `https://kpi-integration.quorumapps.com${url}`)
				.use(finalNoCache)
				.withCredentials()
				.set({ 'x-qomon-token': getCookie('SID'), ...opts.params })
				.query(data)
				.catch(
					opts.catchable
						? (e) => onError(e, url)
						: (e) => {
								throw e
							},
				)
		case 'GET':
		case 'DELETE':
		default: {
			let apiUrl = api

			if (url.includes('http') && !url.includes('/photos?location')) apiUrl = ''

			return request(method, `${apiUrl}${url}`)
				.use(finalNoCache)
				.withCredentials()
				.set(opts.params)
				.query(data)
				.catch(
					opts.catchable
						? (e) => onError(e, url)
						: (e) => {
								throw e
							},
				)
		}
	}
}
